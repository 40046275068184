import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import AboutSeoImage from '../assets/images/direct-patient-care.jpg'

class About extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query AboutMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='about'>
                    <Seo title={`About Dr. Joseph Sambataro, MD | ${data.site.siteMetadata.title}`} description={`Dr. Sambataro is a board certified internal medicine physician.`} image={AboutSeoImage} keywords={[``]} />
                    <Banner title='About Dr. Joseph Sambataro, MD' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={AboutSeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <h4>Dr. Sambataro is a board certified internal medicine physician.</h4>
                                    <p>Born and raised in North Jersey, he is extremely familiar with the needs of patients living in this fast paced, tightly knit part of the country. Dr. Sambataro attended medical school at Rutgers New Jersey Medical School in Newark, NJ and completed his residency in internal medicine at Temple University Hospital in Philadelphia. He decided not long after completing his training that he would much prefer to take care of a finite group of patients that he could get to know well. Knowing his patients well allows him to integrate all avenues of their life to place them on the right track to avoiding illness, staying as healthy as possible but at the same time managing any acute issues or chronic disease the patient's may have. "Providing patients with insight into their bodies, disease process and their treatment plans is the type of education they need in order to feel empowered to take control of their lives. This is my approach to medicine."</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} hasCare={true} hasContact={true} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default About